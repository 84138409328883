import { type ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  // use the `extend` key in case you want to extend instead of override
  override: {
    theme: {
      color: [
        'page-background',
        'page-text',
        'page-widget-border',
        'page-primary-button-text',
        'page-primary-button-border',
        'page-primary-button-background',
        'page-secondary-button-text',
        'page-secondary-button-border',
        'page-divider',
        'page-heading-text',
        'page-body-text',
        'page-body-muted-text',
        'page-icon',
        'page-icon-box-border',
        'page-input-placeholder',
        'page-input-border',
        'page-input-text',
        'page-input-icon',
        'page-card-text',
        'page-card-border',
        'page-card-icon',
      ],
      // borderWidth: [
      //   'page-widget-width',
      //   'page-primary-button-width',
      //   'page-secondary-button-width',
      //   'page-input-width',
      //   'page-card-width',
      //   'page-icon-box-width',
      // ],
      // borderColor: ['border-page-divider'],
    },
    classGroups: {
      'font-family': ['page-heading', 'page-body'],
      'font-weight': ['page-heading-weight', 'page-body-weight'],
      'font-size': [
        'fluid-xxs',
        'fluid-xs',
        'fluid-sm',
        'fluid-base',
        'fluid-lg',
        'fluid-xl',
        'fluid-2xl',
        'fluid-3xl',
        'fluid-4xl',
        'fluid-5xl',
        'fluid-6xl',
      ],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
